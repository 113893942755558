.footer {
  position: relative;
  height: auto;
  color: var(--white-color);
  padding: 7rem 0;
  background-color: var(--footer-color);
}
.footer.container {
  position: relative;
}

.footer h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  margin-top: 0;
  line-height: 1.5;
  color: var(--btn-color);
}
.footer .solcial-info {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.footer .social li {
  display: inline-block;
  position: relative;
  margin: 2px;
}
.footer .social li:hover a > i {
  font-size: 20px;
  transition: all 0.2s linear;
}
.footer .social li a {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.footer .social li a > i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.footer .footer-logo {
  color: var(--white-color);
  width: 66%;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.footer .copyright {
  color: var(--text-color);
}

.footer-content p {
  letter-spacing: 1px;
  line-height: 1.6rem;
}
.footer .cta {
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.16) !important;
  padding: 20px;
  /* margin-bottom: -20px; */
  border-radius: 20px;
  background-color: var(--primary-color);
  top: -150px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.footer .cta h2,
.footer .cta h3 {
  line-height: 1.5;
}
.footer .cta h2 {
  font-weight: bold;
}
.footer .cta h3 {
  font-size: 20px;
}
.footer .cta-btn {
  padding: 12px 30px;
  border: 2px solid var(--footer-color);
  border-radius: 30px;
  color: var(--btn-color);
  font-size: 16px;
  letter-spacing: 1px;
  background-color: var(--primary-color);
}
.footer .cta-btn:hover {
  background-color: var(--footer-color);
  transition: all 0.2s linear;
}

.footer hr {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--text-color);
}
.copyright {
  font-size: 16px;
}

.footer .footer-bottom {
  margin-bottom: -80px;
}
.footer .footer-menu {
  margin-bottom: 0;
}
.footer .footer-menu li {
  display: inline-block;
}

.footer .footer-menu li a {
  margin-left: 20px;
  display: inline-block;
  color: var(--text-color);
  font-family: "Josefin Sans", sans-serif;
}
.footer .footer-menu li a:hover {
  color: var(--primary-color);
  transition: all 0.2s linear;
}
@media only screen and (max-width: 600px) {
  /* .footer {
    width: 100%;
  } */
  .footer,
  .solcial-info {
    display: flex;
    flex-direction: column;
    text-align: center;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .footer .first-row {
    margin-top: -80px;
  }
  .footer .footer-logo-section,
  .footer-content {
    margin-bottom: 40px;
  }
  .footer hr {
    margin: 1rem 0;
  }
  .footer .footer-bottom {
    margin-bottom: -36px;
  }
  .footer .footer-bottom .copyright,
  .footer-menu {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .footer .footer-menu ul {
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
  }
  .footer .footer-menu li a {
    margin-left: 0;
    padding: 0 5px;
    /* justify-content: space-around; */
  }
}
