.heroSection {
  height: 91vh;
  background-color: var(--bgcolor);
}
.heroSection .container {
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
}
.main-content .grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
.left-content {
  align-items: center;
  justify-content: center;
}
.left-content h4 {
  margin-left: 20px;
  margin-top: 10rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--main-text-color);
  font-family: "Poppins", sans-serif;
}
.left-content h1 {
  font-size: 600%;
  color: var(--primary-color);
  font-family: "Righteous", cursive;
}
.left-content h3 {
  font-family: "Josefin Sans", sans-serif;
  text-decoration: none;
  margin-top: 0px;
  color: var(--main-text-color);
}

.left-content h3 span {
  color: #f10b03;
}
.left-content .text {
  line-height: 20px;
  font-size: 1rem;

  font-family: "Poppins", sans-serif;
}
/* .left-content button {
  font-family: "Poppins", sans-serif;
  padding: 0.4rem 1rem;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  color: var(--btn-color);
}

.left-content .btn-primary:hover {
  transition: all 0.3s linear;
  color: var(--btn-color);
}

.left-content .hero-btn2 {
  margin-left: 20px;
  background: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  transition: all 0.3s linear;
}
.left-content .hero-btn2:hover {
  color: var(--btn-color);
  background-color: var(--primary-color);
} */
/* /////////////// */

/* image style */
.images-section {
  width: 66%;
  height: auto;
  position: absolute;
  bottom: 0;
  top: 100px;
  right: 100px;
}
.images-section img {
  height: 100%;
  position: absolute;
  left: 46%;
  bottom: 0;
  transform: translate(-12px);
  transition: bottom 1s, left 1s;
}
.images-section .bg-shape {
  left: 36%;
  height: auto;
  width: 66%;
}
.images-section:hover .bg-shape {
  bottom: 26px;
}
.images-section:hover .main-hero-cover {
  left: 50%;
}
/* home style end */

/* responsive style */

@media (min-width: 350px) and (max-width: 600px) {
  .heroSection {
    height: 113vh;
  }
  .heroSection .container {
    width: 95vw;
  }
  .left-content {
    text-align: justify;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .left-content h4 {
    margin-top: 4rem;
    font-size: 1.2rem;
    margin-left: 0;
  }
  .left-content h3 {
    font-size: 1.4rem;
  }
  .left-content .text {
    font-size: 0.9rem;
  }
  .left-content h1 {
    font-size: 400%;
  }
  .left-content h5 {
    font-size: 1rem;
  }
  .images-section {
    width: 100vw;
    margin: 0 auto;
    right: 16px;
    top: 64%;
  }
  .images-section img {
    top: 12%;
    left: 46%;
    bottom: 0;
    transform: translate(-12px);
    transition: bottom 1s, left 1s;
  }
  .images-section .main-hero-cover {
    left: 28%;
    /* align-items: flex-end; */
    height: auto;
    width: 260px;
    /* display: none; */
  }
  .images-section .bg-shape {
    top: 60%;
    left: 20%;
    margin-bottom: 10px;
    /* align-items: flex-end; */
    height: auto;
    width: 316px;
    /* display: none; */
  }
  .images-section:hover .bg-shape {
    bottom: 0px;
  }
  .images-section:hover .main-hero-cover {
    left: 30%;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .heroSection {
    min-height: 100vh;
  }
  .heroSection .container {
    width: 95vw;
  }
  /* .left-content {
    margin-left: 4%;
  } */
  .left-content h4 {
    margin-top: 6rem;
    font-size: 1rem;
    margin-left: 2%;
  }
  .left-content h3 {
    font-size: 1.6rem;
  }
  .left-content .text {
    font-size: 1rem;
  }
  .left-content h1 {
    font-size: 400%;
  }
  .left-content h5 {
    font-size: 1.1rem;
  }
  .images-section {
    width: 80vw;
    /* margin-top: -10px; */
    right: 16px;
  }

  .images-section .main-hero-cover {
    left: 26%;
    height: auto;
    width: 56%;
  }
  .images-section .bg-shape {
    left: 16%;
    margin-bottom: 10px;
    height: auto;
    /* width: 76%; */
    /* display: none; */
  }
  .images-section img {
    transform: none;
    transition: none;
  }
  .images-section:hover .bg-shape {
    bottom: 0;
  }
  .images-section:hover .main-hero-cover {
    left: 26%;
  }
}

@media screen and (min-width: 1400px) and (max-width: 2000px) {
  /* .container .main-content {
    min-height: 75vh;
    margin-left: 0;
    margin-top: 0;
    height: 85vh;
    width: 50%;
  } */
  .container .main-content h1 {
    font-size: 112px;
    color: var(--primary-color);
    margin-top: 6px;
    font-family: "Righteous", cursive;
  }
  .left-content {
    margin-top: 5rem;
    margin-left: -40px;
  }
  .left-content h4 {
    font-size: 1.8rem;
  }
  .left-content h5 {
    font-size: 20px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
  .left-content h3 {
    font-size: 2.4rem;
  }
  .left-content .text {
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  .left-content button {
    padding: 10px 20px;
    font-size: 1.2rem;
  }
}
