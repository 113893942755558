.servicesSection {
  background-color: var(--bgcolor);
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
}
.servicesSection .section-heading {
  color: var(--text-color);
}
.experience-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  /* background-color: white; */
}
.experience-card h2 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--text-color);
}
.experience-card h1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 6rem;
  color: var(--primary-color);
}
.service-container {
  margin: 0 auto;
}
.grid {
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}
.servicesCard-body {
  width: 100%;
  height: 16rem;
  border: none;
  border-radius: 5px;
  padding: 2rem;
  background-color: var(--card-color);
  cursor: pointer;
  box-shadow: 0 0.5rem 0.6rem rgba(0, 0, 0, 0.046) !important;
  transition: all 0.3s linear;
}
.servicesCard-body:hover {
  box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.274) !important;
}
.servicesCard-body:hover .bg-icon {
  opacity: 0.3;
  transition: all 0.2s linear;
}
.servicesCard-body:hover .card-icon {
  color: var(--primary-color);
  transition: all 0.2s linear;
}
.servicesCard-body:hover .card-title {
  color: var(--primary-color);
  transition: all 0.2s linear;
}
.servicesCard-body:hover .card-desc {
  color: var(--primary-color);
  transition: all 0.2s linear;
}
.servicesCard-body .bg-icon {
  font-size: 4rem;
  text-align: left;
  color: var(--primary-color);
  opacity: 0.2;
}
.servicesCard-body .card-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
}
.servicesCard-body .card-icon {
  font-size: 2.2rem;
  padding: 10px;
  color: var(--icon-color);
  text-align: right;
  position: relative;
}
.servicesCard-body .card-title {
  font-size: 1.3rem;
  color: var(--text-color);
  opacity: 0.8;
  font-family: "Poppins", sans-serif;
}
.servicesCard-body .card-desc {
  font-size: 11px;
  color: var(--main-text-color);
  margin-top: -5px;
  opacity: 0.8;
  font-family: "Poppins", sans-serif;
  /* text-decoration: none; */
}
/* responsive style */
@media (min-width: 350px) and (max-width: 600px) {
  .servicesSection {
    padding: 3rem 0;
  }
}
