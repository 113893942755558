.filter-div {
  text-align: center;
  margin-bottom: 3rem;
}
.filter-div .section-heading {
  color: var(--main-text-color);
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-div .filter-nav-div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-color);
  padding-left: 0;
}
.filter-nav-btn {
  border: none;
  background: transparent;
  margin: 0 1.6rem;
  list-style: none;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--text-color);
  font-family: "Josefin Sans", sans-serif;

  cursor: pointer;
  font-size: 16px;
}
.filter-nav-btn:hover {
  color: var(--primary-color);
  font-weight: 600;
}
/* .filter-nav-btn-active {
  color: var(--primary-color);
  font-weight: 600;
} */
.filter-div .filter-nav-btn:hover,
.filter-div .filter-nav-btn:focus {
  color: var(--primary-color);
  font-weight: 600;
  /*box-shadow: inset 0px -18px 0px -15px var(--primary-color);*/
}
.filter-div .filter-nav-btn:after {
  content: "✔️";
  margin-left: 1rem;
  display: inline-block;
  color: var(--primary-color);
  position: absolute;
  transform: translatey(10px);
  opacity: 0;
  transition: all 0.2s linear;
}
.filter-div .filter-nav-btn--active:after {
  opacity: 1;
  transform: translatey(-2px);
}
.filter-div .filter-bottom-hr {
  /* text-align: center; */
  width: 360px;
  margin: 0 auto;
  border-top: 2px solid var(--text-color);
}
