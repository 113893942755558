.view-More-btn {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 0;
  box-sizing: border-box;
  color: var(--btn-color);
  cursor: pointer;
  display: inline-block;
  /* left: 50%; */
  font-weight: 600;
  letter-spacing: 0.05em;
  margin: 3rem 0 0 0;
  outline: none;
  overflow: visible;
  padding: 0.6rem 1.2rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 12px;
}

.view-More-btn::before {
  content: " ";
  width: 1.5625rem;
  height: 2px;
  background: var(--primary-color);
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
}

.view-More-btn .view-btn-text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--primary-color);
}

.view-More-btn .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.view-More-btn .view-btn-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.view-More-btn .view-btn-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.view-More-btn:hover {
  color: var(--btn-color);
  background: var(--primary-color);
}

.view-More-btn:hover::before {
  width: 0.9375rem;
  background: var(--btn-color);
}

.view-More-btn:hover .view-btn-text {
  color: var(--btn-color);
  padding-left: 1.5em;
}

.view-More-btn:hover .top-key {
  left: -2px;
  width: 0px;
}

.view-More-btn:hover .view-btn-key-1,
.view-More-btn:hover .view-btn-key-2 {
  right: 0;
  width: 0;
}
