.recentProject{
    background-color: var(--white-color);
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
}
.recentProject .project-title {
    color: var(--main-text-color);
    font-size: 34px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
  }
  
  .recentProject .project-subTitle {
    font-size: 1.1rem;
    /* margin: 16px 0; */
    color: var(--main-text-color);
    font-family: "Poppins", sans-serif;
  }

/* .projrct-container{
    margin-bottom: 12px;
} */
.project-img img{
    width: 80%;
    height: auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

@media (min-width: 350px) and (max-width: 600px) {
    .last-project .content{
        order: 2;
    }
    .last-project .img-box{
        order: 1;
    }
}