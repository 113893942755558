.got-to-top-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.top-btn {
  font-size: 1.4rem;
  width: 3rem;
  height: 3rem;
  color: var(--btn-color);
  background-color: var(--primary-color);
  box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.166) !important;
  border-radius: 12%;
  position: fixed;
  bottom: 8rem;
  right: 5rem;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.top-btn--icon {
  animation: gototop 1.2s linear infinite alternate-reverse;
}

@keyframes gototop {
  0% {
    transform: translateY(-0.4rem);
  }
  100% {
    transform: translateY(0.4rem);
  }
}

@media (max-width: 600px) {
  .top-btn {
    right: 0;
    left: 83%;
    /* bottom: 20%; */
  }
}
