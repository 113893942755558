.testimonialSection {
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
  background-color: var(--bgcolor);
}
.m-left {
  margin-left: 5%;
}

.slider-div {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/* //////////////////////////// */

.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper {
  height: auto;
}
.slider-single-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--card-color);

  padding: 3rem;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide .client-profile-image {
  display: block;
  height: 170px;
  width: 170px;
  object-fit: cover;
  border-radius: 50%;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
.stars-div {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
}
.stars-div .start-icon {
  color: gold;
}
.slide .quote-icon {
  font-size: 30px;
  color: var(--primary-color);
}
.slide .details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonial-description {
  padding: 0 5rem;
  font-size: 16px;
}
.details .name {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-text-color);

  font-family: "Poppins", sans-serif;
}
.details .job {
  font-size: 12px;
  font-weight: 400;
  color: var(--main-text-color);
  font-family: "Poppins", sans-serif;
}
@media (min-width: 350px) and (max-width: 600px) {
  .section-heading,
  .section-sub-heading {
    text-align: center;
    margin-left: 0;
  }
  .testimonial-description {
    font-size: 12px;
    padding: 0 2rem;
  }
  .swiper-slide {
    padding: 10px;
  }
  .details {
    padding-bottom: 1rem;
  }
}
