.projectSection {
  background-color: var(--white-color);
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
}
.projectSection .m-left {
  margin-left: 5%;
}
.project-filter {
  text-align: center;
}
.project-filter .section-heading {
  color: var(--main-text-color);
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //////////////////////////// */

.project-card {
  /* margin: 1rem 1rem; */
  /* padding: 1rem; */
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  background-color: var(--card-color);
  border: 0.6px solid var(--card-border-color);
  border-radius: 10px;
  margin-bottom: 3rem;
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.036) !important;
}
.project-card:hover {
  box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.166) !important;
}
.project-card-img-div {
  position: relative;
}
.project-card-img-div:hover .overlay {
  width: 100%;
}

.projectSection figure {
  width: 100%;
  /* height: 16rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s linear;
}
.projectSection figure img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.project-card-detail-div {
  padding: 0 1rem;
}
.project-card .project-desc {
  text-align: justify;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #1866f862;
  overflow: hidden;
  width: 0;
  height: 94%;
  border-radius: 10px;
  transition: 0.6s ease;
}
.overlay-text {
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Poppins", sans-serif;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.projectSection .project-title {
  color: var(--main-text-color);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
}

.project-card .project-subTitle {
  font-size: 16px;
  margin: 16px 0;
  color: var(--main-text-color);
  font-family: "Poppins", sans-serif;
}

.btn-div {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/* ///////////////////////// */
/* single projects view btn style */
.projectSection .singleProject-view-btn {
  width: 100%;
  display: flex;
  text-align: right;
  align-items: center;
  padding: 1rem 0;
}
.projectBtn-cta {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  text-decoration: none;
  font-family: "Josefin Sans", sans-serif;
  margin-left: auto !important;
}

.projectBtn-cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: var(--primary-color);
  opacity: .3;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.projectBtn-cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: var(--primary-color);
}

.projectBtn-cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--primary-color);
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.projectBtn-cta a:link {
  text-decoration: none;
}
.projectBtn-cta:hover:before {
  width: 100%;
  background: var(--primary-color);
  opacity: .5;
}

.projectBtn-cta:hover svg {
  transform: translateX(0);
}

.projectBtn-cta:active {
  transform: scale(0.95);
}

/* responsive style */
@media (min-width: 350px) and (max-width: 600px) {
  .projectSection {
    padding: 3rem 0;
  }
  .projectSection .section-heading {
    text-align: center;
    margin-left: 0;
  }
  .projectSection .section-sub-heading {
    text-align: center;
    margin-left: 0;
  }
  .project-card .project-desc {
    line-height: 1.3rem;
  }
  .projectSection .project-card {
    margin: 2rem 0;
    height: auto;
  }
  .projectSection .singleProject-view-btn {
    padding: .6rem 0;
  }
}
