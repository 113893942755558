.pageHeading {
  /* background-image: url("../../../../public/images/page_heading.jpg"); */
  /* background-color: rgb(245, 245, 245); */
  background-color: var(--bgcolor);
  /* background-size: cover;
  background-attachment: fixed; */
  /* position: absolute; */
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 36vh;
  color: var(--page-heading-color);
  /* text-align: left; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageHeading .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-div {
  display: flex;
  flex-direction: column;
}
.pageHeading .left-div .main-title {
  font-size: 2.6rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.pageHeading h2 {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 20px;
  color: var(--main-text-color);
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}
.pageHeading .right-div {
  display: flex;
  align-items: center;
  text-align: right;
}
.pageHeading .right-div img {
  display: flex;
  text-align: right;
  width: 86%;
}

@media (min-width: 350px) and (max-width: 600px) {
  .pageHeading {
    width: 100%;
    height: 16vh;
  }
  .pageHeading .left-div .main-title {
    font-size: 2rem;
  }
  .pageHeading h2 {
    font-size: 16px;
  }
  .pageHeading .right-div img {
    display: none !important;
  }
}
