.aboutSection {
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
  background-color: var(--bgcolor);
}
.aboutSection .about-container {
  width: 85%;
  max-width: 100%;
  margin: 0 auto;
  align-items: center;
}
.aboutSection .row {
  width: 100%;
  padding: 6rem 0;
  justify-content: space-between;
  align-items: center;
}
.aboutSection-images {
  position: relative;
}

.aboutSection-images img {
  width: 60%;
  height: auto;
  margin-top: 3rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.25);
  border-radius: 1.5rem;
  position: absolute;
}

.aboutSection-images .about-img-img2 {
  position: absolute;
  width: 40%;
  height: auto;
  left: 56%;
  top: 40%;
  box-shadow: 0.6rem 0.6rem 0.6rem rgba(0, 0, 0, 0.25);
}

.about-right-side .about-text {
  /* margin-left: 2rem; */
  /* padding-right: 4rem; */
  width: 100%;
}
.about-right-side .about-text .pera1,
.pera2,
.pera3 {
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 1.4rem;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

.about-right-side .about-mini-title {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.6rem;
  color: var(--text-color);
  padding: 1rem 0;
}

/* ////////////cv button style//////////// */
.cv-button {
  --width: 100px;
  --height: 35px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 18px;
  --tooltip-color: #fff;
  height: 2.6rem;
  width: 10rem;
  padding: 10px 16px;
  background: var(--primary-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em;
  font-family: "Poppins", sans-serif;
  transition: background 0.3s;
}

.btn-text,
.icon {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: var(--btn-color);
  font-family: "Poppins", sans-serif;
  transition: top 0.5s;
}

.icon {
  color: var(--btn-color);
  top: 40%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.cv-button:hover {
  background: #2b035e;
}

.cv-button:hover .btn-text {
  top: -40%;
  opacity: 0;
}
.cv-button:hover .icon {
  top: 0;
  opacity: 1;
}

/* responsive style */
/* @media only screen and (max-width: 600px) { */
@media (min-width: 350px) and (max-width: 600px) {
  .aboutSection {
    height: 172vh;
    padding: 3rem 0;
  }
  .aboutSection .container {
    width: 100vw;
  }
  .aboutSection .container .row {
    margin: 0 auto;
  }
  .aboutSection-images {
    margin-top: 13rem;
  }
  .about-right-side .about-text {
    margin-top: -6rem;
    /* margin-bottom: -66px; */
  }
  .about-text .section-sub-heading {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
  .about-text .pera1,
  .pera2,
  .pera3 {
    text-align: justify;
  }
  .cv-button {
    margin: 0 auto;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .aboutSection {
    height: 130vh;
  }
  .aboutSection-images {
    /* margin-top: 20rem; */
    width: 86%;
    text-align: center;
    margin: 0 auto;
  }
  .as-images {
    margin-top: 18rem;
  }
  .cv-button {
    margin: 0 auto;
  }
}
