.navbar {
  background-color: var(--bgcolor);
  padding: 0 0;
  height: 9vh;
  z-index: 99;
}
.navbar-sticky {
  position: sticky;
  top: 0;
  /* height: 9vh; */
  box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.066) !important;
  transform: translateY(-80px);
  animation: navbar-sticky-animation 0.2s ease forwards;
}
@keyframes navbar-sticky-animation {
  0% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0);
  }
}

.navbar .navbar-brand {
  margin-right: 0;
}
.navbar .navbar-brand .logo {
  width: 12%;
}
.navbar-style {
  display: flex;
  align-items: center;
  justify-content: right;
}
.navbar-lists {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0;
  margin-bottom: 0;
}
.navbar li {
  /* padding: 0 0.4rem; */

  display: flex;
  /* gap: 2rem; */
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 0;
}
.navbar .theme-icon i {
  font-size: 1.2rem;
  color: var(--primary-color);
  cursor: pointer;
  transition: color 0.3s linear;
}
.navbar .theme-icon i:hover {
  color: var(--text-color);
}

.navbar li .navbar-link {
  display: inline-block;
  color: var(--text-color);
  font-size: 1rem;
  font-weight: lighter;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  font-family: "Josefin Sans", sans-serif;
  transition: color 0.3s linear;
}
.navbar li > .navbar-link:hover {
  color: var(--primary-color);
}
.navbar li .navbar-link-active {
  text-decoration: none;
  font-weight: 500;
  color: var(--primary-color);
  /* font-family: "Josefin Sans", sans-serif; */
}

.mobile-navbar-btn {
  display: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
}
/*menu icon style start*/
.menu-btn{
  width: 40px;
  height: 30px;
  /*margin-left: 6px;*/
}
.menu-btn .icon-box{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.menu-btn .icon-box .span-1{
  width: 100%;
  height: 6px;
  margin-left: auto !important;
  background: var(--primary-color);
  border-radius: 10px;
}
.menu-btn .icon-box .span-2{
  width: 70%;
  height: 6px;
  background: var(--primary-color);
  border-radius: 10px;
  margin: 4px 0;
  margin-left: auto !important;
}
.menu-btn .icon-box .span-3{
  width: 50%;
  height: 6px;
  margin-left: auto !important;
  background: var(--primary-color);
  border-radius: 10px;
}
/*menu icon style end*/

.mobile-nav-icon .close-outline {
  display: none;
}

.close-outline {
  display: none;
}

@media (min-width: 350px) and (max-width: 600px) {
  .navbar {
    background-color: var(--bgcolor);
    width: 100%;
    min-height: 10vh;
  }
  /* .navbar-sticky {
    height: 6vh;
    min-height: 10vh;
  } */
  .navbar .container {
    display: flex;
    flex-wrap: unset;
    width: 100%;
    max-width: 100%;
    top: 0;
    padding: 0 2rem;
    justify-content: space-between;
    background-color: var(--bgcolor);
    /* box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.066) !important; */
    position: fixed;
    z-index: 999;
  }
  .navbar .navbar-brand .logo {
    width: 16%;
  }
  .mobile-navbar-btn {
    display: inline-block;
    z-index: 9999;
    border: var(--black-color);
  }

  .mobile-nav-icon {
    font-size: 2rem;
    color: var(--text-color);
  }
  .active .mobile-nav-icon {
    display: inline-block;
    font-size: 2rem;
    position: absolute;
    top: 22%;
    right: 9%;
    color: var(--primary-color);
    /*color: var(--text-color);*/
    z-index: 9999;
  }
  .menu-btn{
    width: 36px;
    height: 20px;
    /*margin-left: 6px;*/
  }
  .menu-btn .icon-box .span-1{
    width: 90%;
    height: 5px;
  }
  .menu-btn .icon-box .span-2{
    width: 60%;
    height: 5px;
  }
  .menu-btn .icon-box .span-3{
    width: 40%;
    height: 5px;
  }

  .close-outline {
    display: inline-block;
  }

  .navbar-lists {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white-color);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    visibility: hidden;
    opacity: 0;
    transform: translateX(0);
    /* transform-origin: top; */
    transition: all 0.6s linear;
  }

  .active .navbar-lists {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    z-index: 999;

    transform-origin: right;
    padding-left: 0;
    transition: all 1s;
  }
  .active .navbar-link {
    font-size: 1.4rem;
    transition: all 0.1s linear;
  }
  .navbar-link:hover {
    color: var(--primary-color);
    transform: scale(1.1);
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .navbar {
    background-color: var(--bgcolor);
    width: 100%;
    height: auto;
  }
  .navbar .container {
    display: flex;
    flex-wrap: unset;
    width: 100%;
    max-width: 100%;
    top: 0;
    padding: 0 2rem;
    justify-content: space-between;
    background-color: var(--bgcolor);
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.066) !important;
    /* position: fixed; */
    z-index: 999;
  }
  .navbar .navbar-brand .logo {
    width: 16%;
  }
  .navbar .theme-icon i {
    font-size: 1.6rem;
  }
  .mobile-navbar-btn {
    display: inline-block;
    z-index: 9999;
    border: var(--black-color);
  }

  .mobile-nav-icon {
    font-size: 2rem;
    color: var(--text-color);
  }
  .active .mobile-nav-icon {
    display: inline-block;
    font-size: 2rem;
    position: absolute;
    top: 32%;
    right: 4%;
    /*color: var(--text-color);*/
    color: var(--primary-color);
    z-index: 9999;
  }

  .close-outline {
    display: inline-block;
  }

  .navbar-lists {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white-color);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    visibility: hidden;
    opacity: 0;
    transform: translateX(0);
    /* transform-origin: top; */
    transition: all 0.6s linear;
  }
  .navbar-lists li a {
    font-size: 1.3rem !important;
  }

  .active .navbar-lists {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    z-index: 999;

    transform-origin: right;
    padding-left: 0;
    transition: all 1s;
  }
  .active .navbar-link {
    font-size: 1.6rem;
    transition: all 0.1s linear;
  }
  .navbar-link:hover {
    color: var(--primary-color);
    transform: scale(1.1);
  }
}
