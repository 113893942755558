.errorSection {
  width: 100%;
  height: auto;
  padding: 2rem 0;
  margin: 0 auto;
  align-items: center;
  background-color: var(--white-color);
}
.errorSection .container,
.main-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.errorSvg {
  width: 160%;
}
.error-title {
  /* margin: 1rem 0; */
  font-size: 3rem;
  font-weight: 600;
  color: var(--main-text-color);
  /* color: #3f3d56; */
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

/* go to home button style */
.gotToHome-btn {
  border: none;
  display: block;
  position: relative;
  padding: 0.6rem 1.6rem;
  font-size: 16px;
  font-weight: 600;
  background: transparent;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  color: var(--primary-color);
  z-index: 1;
  font-family: inherit;
  font-weight: 500;
  margin-bottom: 2rem;
}
.gotToHome-btn .gotToHome-btn-span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: -1;
  border: 2px solid var(--primary-color);
}

.gotToHome-btn .gotToHome-btn-span::before {
  content: "";
  display: block;
  position: absolute;
  width: 10%;
  height: 500%;
  background: var(--lightgray);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-60deg);
  transition: all 0.3s;
}

.gotToHome-btn:hover .gotToHome-btn-span::before {
  transform: translate(-50%, -50%) rotate(-90deg);
  width: 100%;
  background: var(--primary-color);
}

.gotToHome-btn:hover {
  color: var(--btn-color);
}

.gotToHome-btn:active .gotToHome-btn-span::before {
  background: var(--primary-color);
}

/* responsive style */
@media (min-width: 350px) and (max-width: 600px) {
  .errorSection {
    padding: 8rem 0;
  }
  .errorSvg {
    width: 86%;
  }
  .error-title {
    font-size: 2rem;
  }
  .gotToHome-btn {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .errorSection {
    padding: 4rem 0;
  }
  .errorSvg {
    width: 66%;
  }
  .error-title {
    font-size: 3rem;
  }
  .gotToHome-btn {
    margin-bottom: 3rem;
  }
}
@media screen and (min-width: 1400px) and (max-width: 2000px) {
  .errorSection {
    padding: 6rem 0;
  }
}
