.gallerySection {
  background-color: var(--bgcolor);
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
}
.gallerySection .m-left {
  margin-left: 5%;
}
/* .gallerySection .all-images {
  padding: 10px;
} */
/* //////////////////////////// */
.imgStyle {
  width: 100%;
  display: block;
  cursor: pointer;
  transition: all 0.2s linear;
}
.imgStyle:hover {
  box-shadow: 0 0.5rem 0.6rem rgba(0, 0, 0, 0.266) !important;
}
.preView-img--div {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.816);
  display: flex;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.4s ease-in-out;
  transform: scale(0);
  z-index: 999;
}
.preview-open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.preview-img--style {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
}
.image-close-icon {
  position: fixed;
  top: 26px;
  right: 32px;
  font-size: 2rem;
  padding: 12px;
  color: var(--btn-color);
  cursor: pointer;
}
.image-close-icon:hover {
  color: red;
  transition: 0.2s linear;
}

/* //////////////////////view button style////////////////////// */

/* button styling */
.btn-div {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/* responsive style */
@media (min-width: 350px) and (max-width: 600px) {
  .gallerySection {
    padding: 3rem 0;
  }
  .gallerySection .section-heading {
    text-align: center;
    margin-left: 0;
  }
  .gallerySection .section-sub-heading {
    text-align: center;
    margin-left: 0;
  }
}
