.factSection {
  /* height: 40vh; */
  padding: 80px 0;
  background-color: var(--facts-bg-color);
}
.factSection .single-div {
  cursor: pointer;
}
.factSection .icon-div {
  text-align: right;
  border-right: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.factSection .icon-div i {
  margin-right: 6px;
}
.factSection .text-div {
  text-align: left;
}
.facts-icon {
  font-size: 3rem;
}
.factSection .text-div h1 {
  font-weight: 600;
  margin-left: 12px;
}
.factSection .text-div .text-div-p {
  color: var(--text-color);
  margin-left: 12px;
}

@media (min-width: 350px) and (max-width: 600px) {
  .factSection .icon-div i {
    margin-right: 12px;
  }
  .factSection .text-div .text-div-heading,
  .text-div-p {
    margin-left: 16px;
  }
}
