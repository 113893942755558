.servicesEndData {
  background-color: var(--bgcolor);
  width: 100%;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 6rem;
  margin: 0 auto;
  align-items: center;
}
.services-data {
  /* margin: 1rem 1rem; */
  padding: 1rem;
  height: 16rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.services-data:hover .servicesData-title {
  color: var(--primary-color);
  transition: all 0.2s linear;
}
.services-data:hover .servicesData-icon {
  color: var(--primary-color);
  transition: all 0.2s linear;
}

.services-data .servicesData-icon {
  font-size: 1.8rem;
  margin-top: 20px;
  border-radius: 50%;
  color: var(--icon-color);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.services-data .servicesData-title {
  margin: 1.4rem 0;
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
}
.services-data .servicesData-desc {
  font-size: 15px;
}
