.contactSection {
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
  background-color: var(--bgcolor);
}
.contactSection .m-left {
  margin-left: 5%;
}

.contactSection .initialAddress {
  background-color: var(--card-color);
  width: 100%;
  height: auto;
  cursor: pointer;
  padding: 1rem 0.6rem;
  border-radius: 12px;
  box-shadow: 0 0.5rem 0.6rem rgba(0, 0, 0, 0.036) !important;
}
.initialAddress .phone,
.email,
.address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.2rem 0;
}
.init-heading,
.init-desc {
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  color: var(--main-text-color);
}
.init-icon-style {
  font-size: 1.2rem;
  padding: 10px;
  margin-bottom: 6px;
  border: none;
  border-radius: 50%;
  color: var(--primary-color);
  background-color: var(--bgcolor);
}
.init-react-icon-style {
  font-size: 2.4rem;
  padding: 6px;
  margin-bottom: 6px;
  border: none;
  border-radius: 50%;
  color: var(--primary-color);
  background-color: var(--bgcolor);
}
.contactSection .contact-div {
  background-color: var(--card-color);
  width: 100%;
  height: auto;
  padding: 4rem 1rem;
  margin-bottom: 3rem;
  border-radius: 12px;
  box-shadow: 0 0.5rem 0.6rem rgba(0, 0, 0, 0.066) !important;
}
.contactSection .contact-div figure {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
}
.contactSection .svg-div figure img {
  width: 100%;
}
.contact-div .svg-div,
.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-div .form-heading {
  margin-bottom: 20px;
  padding: 0.6rem 0;
  font-family: "Roboto", sans-serif;
}
.form-div .form-heading .form-heading-text {
  font-size: 26px;
  font-weight: 600;
  color: var(--main-text-color);
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}
.form-div .form-text {
  margin-left: 1.6%;
}
/* From uiverse.io by @alexruix */
.form-div .form-control {
  line-height: 28px;
  border: 2px solid transparent;
  border-bottom-color: rgba(119, 119, 119, 0.562);
  padding: 0.2rem 0.6rem;
  outline: none;
  background-color: transparent;
  color: var(--main-text-color);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.form-div .form-control:focus,
input:hover {
  outline: none;
  /* padding: 0.2rem 1rem; */
  /* border: 2px solid #7a9cc669; */
  border-radius: 0.2rem;
  border-color: #7a9cc669;
}

.form-div .form-control::placeholder {
  color: var(--text-color);
  padding: 0 0.4rem;
  opacity: 0.6;
}

.form-div .form-control:focus::placeholder {
  opacity: 0;
  transition: opacity 0.2s;
}
.text-area {
  resize: none;
  height: 120px;
}
.form-text {
  color: var(--main-text-color);
}
.form-check-label {
  color: var(--main-text-color);
}

/* form submit button style */
.submit-btn {
  font-family: inherit;
  font-size: 20px;
  background: var(--primary-color);
  color: var(--btn-color);
  width: 100%;
  max-width: 100%;
  padding: 0.34rem 0;
  /* padding-left: 0.9em; */
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
}

.submit-btn span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.submit-btn svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.submit-btn:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.submit-btn:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.submit-btn:hover span {
  transform: translateX(66rem);
}

.submit-btn:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

/* alert style */
.submit-alert {
  color: rgb(2, 192, 2);
  margin-bottom: 0;
  margin-top: 10px;
}

/* responsive style */
@media (min-width: 350px) and (max-width: 600px) {
  .contactSection {
    padding: 3rem 0;
  }
  .contactSection .section-heading {
    text-align: center;
    margin-left: 0;
  }
  .contactSection .section-sub-heading {
    text-align: center;
    margin-left: 0;
  }

  .initialAddress .email {
    margin: 1rem 0;
  }
  .contact-div .form-heading {
    margin-top: 2rem;
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .initialAddress .email {
    margin: 1rem 0;
  }
  .contact-div .form-heading {
    margin-top: 1.6rem;
  }
}
