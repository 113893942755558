.skillsSection {
  width: 100%;
  height: auto;
  padding: 6rem 0;
  margin: 0 auto;
  align-items: center;
  background-image: url("../../../public/images/skills_bg.png");
  background-color: var(--bgcolor);
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* background-attachment: fixed; */
  /* position: absolute; */
}
.skillsSection .section-sub-heading {
  padding-bottom: 2.6rem;
}
.skillsSection .m-left {
  margin-left: 5%;
}
.skillsSection .col {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.skillsSection .skills-icon-div .skill-icon-svg {
  width: 90%;
}
.skillsSection .skills-icon-div .knowledge-icon-svg {
  width: 90%;
  margin-top: -66%;
}

.skillsSection .skills-lists p {
  margin-bottom: 0;
  padding: 5px 0;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s linear;
  opacity: 0;
  animation: fadeIn 0.4s ease-in both;
}
.skillsSection .skills-lists p:hover {
  transform: scale(1.1);
  color: var(--primary-color);
}

/* .skillsSection .skills-lists .fadeAnimation {
  opacity: 0;
  animation: fadeIn 1s ease-in both;
} */
.skillsSection .skills-lists p:nth-child(2) {
  animation-delay: 0.1s;
}
.skillsSection .skills-lists p:nth-child(3) {
  animation-delay: 0.3s;
}
.skillsSection .skills-lists p:nth-child(4) {
  animation-delay: 0.6s;
}
.skillsSection .skills-lists p:nth-child(5) {
  animation-delay: 0.9s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* responsive design */
@media (min-width: 350px) and (max-width: 600px) {
  .skillsSection {
    padding: 3rem 0;
  }
  .skillsSection .section-heading {
    text-align: center;
    margin-left: 0;
  }
  .skillsSection .section-sub-heading {
    text-align: center;
    margin-left: 0;
    padding-bottom: 2px;
  }
  .skillsSection .skills-lists p {
    font-size: 1.2rem;
  }
  .skillsSection .col {
    padding: 0.9rem;
    margin: 0 auto;
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .skillsSection .skills-icon-div .skill-icon-svg {
    width: 110%;
  }
  .skillsSection .skills-icon-div .knowledge-icon-svg {
    width: 110%;
    margin-top: -66%;
  }
}
