@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
body {
  scroll-behavior: smooth;
}

/* svg color: #4485ff;  */

/* font-family: 'Josefin Sans', sans-serif; (for nav)
  font-family: "Poppins", sans-serif;  (p tag)
font-family: 'Open Sans', sans-serif;
font-family: 'Righteous', cursive; (for main heading)
font-family: 'Roboto', sans-serif;
font-family: 'Secular One', sans-serif;*/

:root {
  --bgcolor: #f1f5fd;
  --primary-color: #1867f8;
  --text-color: #828286;
  --main-text-color: #333333;
  --white-color: white;
  --card-color: white;
  --btn-color: white;
  --facts-bg-color: #333333;
  --footer-color: #141d2a;
  --black-color: black;
  --page-heading-color: rgb(231, 231, 231);
  --interest-bg-color: #8ecae6;
  --icon-color: #555555;
  --card-border-color: #f1f1f1;
}

/* theme style */
.light-theme {
  --bgcolor: #f1f5fd;
  --primary-color: #1867f8;
  --text-color: #828286;
  --main-text-color: #333333;
  --white-color: white;
  --card-color: white;
  --btn-color: white;
  --facts-bg-color: #333333;
  --footer-color: #141d2a;
  --black-color: black;
  --page-heading-color: rgb(231, 231, 231);
  --interest-bg-color: #8ecae6;
  --icon-color: #555555;
  --card-border-color: #f1f1f1;
}

.dark-theme {
  --bgcolor: #121520;
  --primary-color: #1867f8;
  --text-color: #bcbcbd;
  --main-text-color: #e2dcdc;
  --white-color: #121520;
  --card-color: #383c48;
  --btn-color: white;
  --facts-bg-color: #383c48;
  --footer-color: #141d2a;
  --black-color: rgb(255, 255, 255);
  --page-heading-color: rgb(28, 29, 32);
  --interest-bg-color: #383c48;
  --icon-color: #cccccc;
  --card-border-color: #646464;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
}
p {
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
}

.section-heading {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: start;
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
.section-sub-heading {
  font-size: 2rem;
  text-align: start;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--main-text-color);
  font-family: "Poppins", sans-serif;
}

  /* ///////////// Primary btn style //////////////// */
  .primary-btn-style {
    border: none;
    margin-top: 1rem;
    color: #fff;
    background-image: linear-gradient(30deg, #0400ff, #4ce3f7);
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 16px;
    padding: 0.6em 1.5em;
    text-decoration: none;
   }
   
   .primary-btn-style:hover {
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse512 1.5s infinite;
    color: var(--main-text-color);
    transition: all .2s linear;
   }
   
   @keyframes pulse512 {
    0% {
     box-shadow: 0 0 0 0 #05bada66;
    }
   
    70% {
     box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }

     /* ///////////// Secondary btn style //////////////// */
  .secondary-btn-style {
    border: 2px solid var(--primary-color);
    /* background-image: linear-gradient(30deg, #0400ff, #4ce3f7); */
    margin-top: 1rem;
    margin-left: 16px;
    background-color: transparent;
    color: var(--primary-color);
    border-radius: 20px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 16px;
    padding: 0.6em 1.5em;
    text-decoration: none;
   }
   
   .secondary-btn-style:hover {
    background-image: linear-gradient(30deg, #0400ff, #4ce3f7);
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse512 1.5s infinite;
    color: white;
    transition: all .2s linear;
   }
   
   @keyframes pulse512 {
    0% {
     box-shadow: 0 0 0 0 #05bada66;
    }
   
    70% {
     box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }

/* --------------------------------- scroll bar style  --------------------------------- */
body::-webkit-scrollbar {
  width: 0.6rem;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(132, 132, 132);
  border-radius: 20px;
}

/* --------------------------------- responsive media queries  --------------------------------- */
@media (min-width: 1500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px !important;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}
/* @media (min-width: 350px) and (max-width: 600px) {
  .text-start {
    text-align: center;
  }
} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
