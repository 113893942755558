.interestedSection {
  /* background-color: #219ebc; */
  /* background-color: #1e80c1; */
  /* 2f2e41 */
  /* background: linear-gradient(to left, #bde0f0 0%, #8ecae6 100%); */
  background-color: var(--interest-bg-color);
  width: 100%;
  height: auto;
}
.interestedSection .container {
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.interestedSection .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.interestedSection .text-div {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: left;
}
.interestedSection .text-div .int-desc {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: -12px;
  color: var(--main-text-color);
  text-align: left;
}

.interestedSection .int-svg-div {
  display: flex;
  align-items: center;
  justify-content: right;
}
.int-svg-div .int-svg {
  width: 36%;
  display: flex;
  align-items: center;
}
/* int btn style */
.int-btn {
  font-weight: lighter;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.6px;
  color: var(--btn-color);
  border-radius: 0.6rem;
  padding: 0.6rem 1rem;
  margin: 1rem 0;
  border: none;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.int-btn:hover {
  background-color: #2746f3;
  transition: all 0.2s linear;
}
.copy-icon {
  margin: 0 6px;
}

/* responsive style */
@media (min-width: 350px) and (max-width: 600px) {
  .interestedSection {
    height: auto;
    padding: 1rem;
  }
  .interestedSection .container {
    padding: 0 1rem;
  }
  .interestedSection .text-div .int-desc {
    text-align: center;
  }
  .interestedSection .int-svg-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .int-btn {
    margin: 1rem auto !important;
  }
  .int-svg-div .int-svg {
    margin-top: 0;
    display: flex;
    text-align: center;

    align-items: center;
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .interestedSection .text-div {
    padding: 1rem 0;
  }
  .interestedSection .text-div .int-desc {
    font-size: 0.9rem;
  }
  .int-svg-div .int-svg {
    width: 49%;
  }
}
